import Helmet from "react-helmet";
import React, { useEffect } from "react";
import ExtendedHomepage from "src/components/extendedHomepage";
import HomepageLayout from "src/layouts/homepage";
import NonSearchDisclosure from "src/components/nonsearchdisclosure";
import ChromeCTADisclosure from "src/components/chromectadisclosure";
import { isChrome } from "@tightrope/lpscripts/browserdetect";

const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;
  if (isChrome()) {
    disclosure = <ChromeCTADisclosure data={homepageData}></ChromeCTADisclosure>;
  } else {
    disclosure = <NonSearchDisclosure data={homepageData}></NonSearchDisclosure>;
  }

  return (
    <HomepageLayout>
      <Helmet>
        <style type="text/css">{`
          #ctadisclosurenewtab-module--ctadisclaimer, #ctadisclosure-module--ctadisclaimer {
            font-weight: 300;
            color: #f0f0f0;
          }

          #ctadisclosurenewtab-module--ctadisclaimer p, #ctadisclosure-module--ctadisclaimer p {
            color: #f0f0f0;
          }

          #extendedhomepage-module--middle #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure p,
          #extendedhomepage-module--bottom #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure p {
            color: #333;
          }

          #extendedhomepage-module--middle #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure a,
          #extendedhomepage-module--bottom #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure a {
            color: #007bff;
          }
              
            .footerlinkssingle-module--br {
            text-align:center;
        }
        `}</style>

        <title>Easy View</title>
      </Helmet>

      <ExtendedHomepage data={homepageData}>
        {disclosure}
      </ExtendedHomepage>
    </HomepageLayout>
  );
}
