import Helmet from "react-helmet"
import React from "react"
import AmoTyp from "src/components/amotyp"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"

export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
     body {
       background: #A7E8DF;
     }
       .footerlinkssingle-module--br--SJsBa {
         position: absolute;
         bottom: 10px;
         left: 50%;
         transform: translateX(-50%);
       }

       ..footerlinkssingle-module--br {
       background: white;}

       .amotyp-module--amoContainer img#amotyp-module--logo {
         height:40px;
       }

       .amotyp-module--amoContainer #amotyp-module--imsg {
         padding:20px 80px;
       }
       .amotyp-module--amoContainer span#amotyp-module--installMsg, #amotyp-module--imsg span {
         font-size:56px;
         font-weight:bold;
         color:#0d4b63;
         line-height:125%;
       }
       img#amotyp-module--logo--GtTlw {
         height: 40px!important;
       }
       .amotyp-module--amoContainer .amotyp-module--aUp {
         top:160px;
       }

       .amotyp-module--amoContainer .amotyp-module--aCard {
         margin: 0;
       }
       .topContainer {
         display: flex;
         flex-direction: column;
         height: 100%;
         justify-content: space-between;
         max-width: 960px;
         min-width: 960px;
         margin: 0 auto;
         height: 85vh;
         background: #A7E8DF;
       }
       .amotype-module--amoContainer {
         display: flex;
         flex-direction: row-reverse;
         gap: 50px;
         justify-content: space-between;
         width: 100%;
         margin-top: 200px;
       }
       .amotyp-module--amoContainer .amotyp-module--aCard p {
         width:100%;
       }
       .tyBottom {
         display:flex;
         flex-wrap:wrap;
         justify-content:center;
         padding:50px 0;
         margin: 0 200px;
         background: white;
       }
       .tyBottom div {
         width:50%;
       }
       .tyBottom h2 {
         flex-basis:100%;
         font-size:40px;
         font-weight:bold;
         color:#0d4b63;
         line-height:125%;
         padding:0 0 40px 0;
         text-align:center;
       }
       .tyBottom div {
         padding:64px 0 64px 80px;
       }
       .tyBottom div:last-child {
         text-align:center;
         padding:64px 0;
       }
       .tyBottom p, .tyBottom ul {
         font-size:28px;
       }

       .light-dark-img {
         -webkit-animation: imgcolorchange 4s infinite; /* Chrome, Safari, Opera */ 
         animation: 4s infinite imgcolorchange;
       }
       @keyframes imgcolorchange {
         0% {
           background: url(/img/light-mode.png) bottom center / 250px no-repeat;
         }
         50% {
           background: url(/img/dark-mode.png) bottom center / 250px no-repeat;
         }
         100% {
           background: url(/img/light-mode.png) bottom center / 250px no-repeat;
         }
       }
       /* Chrome, Safari, Opera */
       @-webkit-keyframes imgcolorchange {
         0%   {background: url(/img/light-mode.png) bottom center / 250px no-repeat;}
         50%  {background: url(/img/dark-mode.png) bottom center / 250px no-repeat;}
         100%   {background: url(/img/light-mode.png) bottom center / 250px no-repeat;}
       }

       .amotype-module--amoContainer .amotype-module--aCard {
         margin: 0;
       }
    }
    `}
    </style>
    <title>Thank you for downloading</title>
    </Helmet>

    <section>
      <div class="topContainer">
      <AmoTyp data={Data} siteData={SiteData}></AmoTyp>
      </div>
      <div class="tyBottom">
      <h2>Switch Between Light and Dark Mode</h2>
      <div>
      <p>Easy View:</p>
      <ul>
      <li>No Advertisements</li>
      <li>No Images (save on ink!)</li>
      <li>Adjust font size for readability</li>
      <li>View in Dark/Light Mode</li>
      <li>Hightlight important text</li>
      <li>Start now by clicking the extension icon!</li>
      </ul>
      </div>
      <div class="light-dark-img">
      </div>
      </div>
      <FooterlinksSingle></FooterlinksSingle>
    </section>
    </>
  )
}
